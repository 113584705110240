<template>
	<div id="accordion" class="cursor-pointer" @mouseover="showHere = true" @mouseleave="showHere = false" @click="openTab">
		<div class="">
			<div class="card-header">
				<div class="mb-0 flex justify-content-between w-full">
					<p data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne" class="__faq">
						{{ data.question }}
					</p>
					<span class="mr-3" v-if="data.is_company === true && systemMode === 'whitelabel' && showHere === true">
 				<span class="text-gray-900 float-right mr-1 cursor-pointer" v-on:click="deleteFaq"><i
					class="el-icon-delete-solid text-danger"></i></span>
        		<span class="text-gray-900 float-right mr-2 cursor-pointer" v-on:click="editFaq"><i
					class="el-icon-edit text-info"></i></span>
		            </span>
				</div>
				<div class="pos-icon" v-on:click="openTab"><i
					:class="{'el-icon-minus': checked === true ,'el-icon-plus': checked === false}"></i>
				</div>
			</div>

			<div class="collapse" :class="{'show': checked === true}" data-parent="#accordion">
				<el-card shadow="never">
					<p v-html="data.answer"></p>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccordionComponent',
	props: {
		data: {
			type: Object,
			required: true
		},
		id: {
			type: Number,
			required: true
		},
	},
	data: function () {
		return {
			checked: false,
			showHere: false,
			item: {},
			systemMode:''
		}
	},
	mounted() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
	},
	methods: {
		openTab: function () {
			this.checked = !this.checked
		},
		editFaq: function () {
			window.Bus.$emit('open-edit-faq-modal', {data: this.data, id: this.data.id})
		},
		deleteFaq: function () {
			this.$confirm(`${this.$t('removeFaq')} ${this.data.question}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						this.$store.dispatch('portal/deleteWhiteLabelFaq', {id: this.data.id}).then(_ => {
							instance.confirmButtonLoading = false
							done()
							window.Bus.$emit('remove-faq', {id: this.data.id})
						}).catch(error => {
							done()
							instance.confirmButtonLoading = false
							if (error.response.status === 400 || error.response.status === 500) {
								this.$services.helpers.notification(this.$t('serverError'), 'error', this)
							}
						})
					} else {
						done()
						instance.confirmButtonLoading = false
					}
				}
			}).then(() => {
				// window.Bus.$emit('remove-faq', {id: this.data.id})
			})
				.catch(() => {

				})
		}
	}
}
</script>

<style scoped>
.pos-icon {
	float: right;
	position: absolute;
	right: 10px;
}

@media only screen and (max-width: 640px) {
	.pos-icon {
		position: relative;
		top: -16px;
	}

	.__faq {
		text-align: left;
	}
}
</style>
