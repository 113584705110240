<template>
  <div class="flex justify-content-between w-full" @mouseover="showHere = true" @mouseleave="showHere = false">
    <p class="text-gray-900" v-on:click="filterCategory">{{ data.title }}</p>
    <p class="text-gray-900 flex"
       v-if="systemMode === 'whitelabel' && data.is_company === true && showHere === true">
												<span class="text-gray-900 float-right mr-2 cursor-pointer" v-on:click="editFaqCategory"
                        ><i
                            class="el-icon-edit text-info"></i></span>
      <span class="text-gray-900 float-right mr-2 cursor-pointer" v-on:click="deleteFaqCategory"
      ><i
          class="el-icon-delete-solid text-danger"></i></span>

    </p>
  </div>
</template>

<script>
export default {
  name: "faqCategoryComponent",
  props: {
    data: {
      type: Object
    }
  },
  data: function () {
    return {
      systemMode: '',
      showHere: false
    }
  },
  mounted() {
    this.systemMode = process.env.VUE_APP_SYSTEM_MODE
  },
  methods: {
    filterCategory: function () {
      this.$emit('filterCategory', this.data.id)
    },
    editFaqCategory: function () {
      window.Bus.$emit('open-edit-faq-category-modal', {data: this.data, id: this.data.id})
    },
    deleteFaqCategory: function () {
      this.$confirm(`${this.$t('removeFaqCategory')} ${this.data.title}?`, `${this.$t('warning')}`, {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Loading...'
            this.$store.dispatch('portal/deleteWhitelabelFaqCategory', {id: this.data.id}).then(_ => {
              instance.confirmButtonLoading = false
              done()
              window.Bus.$emit('remove-faq-category', {id: this.data.id})
            }).catch(error => {
              done()
              instance.confirmButtonLoading = false
              if (error.response.status === 400 || error.response.status === 500) {
                this.$services.helpers.notification(this.$t('serverError'), 'error', this)
              }
            })
          } else {
            done()
            instance.confirmButtonLoading = false
          }
        }
      }).then(() => {
        window.Bus.$emit('remove-faq-category', {id: this.data.id})
      })
          .catch(() => {

          })
    }
  }
}
</script>

<style scoped>

</style>
