<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row">
				<div class="col-lg-9 col-md-9 col-sm-12">
					<div class="row no-gutters space-y-4 lg:space-y-0">
						<div class="col-6">
							<div>
								<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer ml-3">
									<span data-cy='article-heading'
										  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
											$t('faqzFullName')
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="flex">
								<button v-if="systemMode === 'whitelabel'" v-on:click="addFaq"
										class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 mr-2 py-2 rounded-md bg-blue-800 text-white w-2/5 text-center">
									<span>{{ $t('addFaq') }}</span>
								</button>
								<el-input style="float: right !important;"
										  :class="{'w-60': systemMode === 'whitelabel','w-full': systemMode !== 'whitelabel'}"
										  v-bind:placeholder="$t('search')" @input="onSearch" v-model="searchName">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
							</div>


						</div>
					</div>
					<div class="row no-gutters space-y-4 lg:space-y-0 mt-3 overflow-y-scroll mb-3"
						 v-infinite-scroll="loadMore">
						<div class="col-md-12 col-lg-12" v-for="(item,key) in GET_FAQ.data" v-bind:key="key">
							<AccordionComponent :data="item" :id="key"/>
						</div>
						<div class="col-md-12">
							<p class="mb-3 mt-1 flex justify-center space-x-3 text-center text-gray-500"
							   v-if="loadingFaqz">
                                <span>
                                    <svg class="animate-spin mr-2 h-5 w-5 text-gray-400"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
								{{ $t('loading') }}...
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12">
					<div class="bg-white border rounded-md p-1">
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<div class="flex justify-content-between">
								<span class="font-semibold text-lg text-gray-900">{{ $t('category') }}</span>
								<span class="font-semibold text-lg text-gray-900 pt-1 cursor-pointer"
									  v-on:click="addCategory"
									  v-if="systemMode === 'whitelabel'"><img alt="add category"
																			  src="../../assets/images/icons/add-circle-plus.svg"/></span>
							</div>
							<div class="flex flex-col">
								<ul class="space-y-2" v-if="GET_FAQ_CATEGORY.data">
									<li class="flex align-items-center space-x-4 cursor-pointer"
										v-for="(item,key) in GET_FAQ_CATEGORY.data" :key="key">
										<faq-category-component :data="item" @filterCategory="filterCategory"
																:key="key"/>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Vue from "vue";
import AccordionComponent from './components/AccordionComponent'
import debounce from 'lodash.debounce'
import faqCategoryComponent from "./components/faqCategoryComponent";


export default {
	name: 'Faqz',
	components: {AccordionComponent, faqCategoryComponent},
	data: function () {
		return {
			limit: 30,
			searchName: '',
			categoryName: '',
			loadingFaqz: false,
			more: false,
			systemMode: '',
		}
	},
	computed: {
		...mapGetters({
			GET_FAQ: 'portal/GET_FAQ',
			GET_FAQ_CATEGORY: 'portal/GET_FAQ_CATEGORY',
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',

		})
	},
	methods: {
		getCompanyDetails() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.getFaqs()
					this.getFaqCategory()
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		getFaqs: function () {
			let pageUrl = `?limit=${this.limit}&company_id=${this.GET_COMPANY_SETTING.id}`
			this.$store.dispatch('portal/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getFaqCategory: function () {
			let pageUrl = `?company_id=${this.GET_COMPANY_SETTING.id}`
			this.$store.dispatch('portal/getFaqCategory', {data: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		loadMore: function () {
			if (this.GET_FAQ.current_page < this.GET_FAQ.total_pages) {
				this.loadingFaqz = true
				let pageUrl = `?page=${this.GET_FAQ.current_page + 1}&limit=${this.limit}&company_id=${this.GET_COMPANY_SETTING.id}`
				if (this.categoryName !== '') {
					pageUrl = pageUrl + `&category__id=${this.categoryName}`
				}
				if (this.searchName !== '') {
					pageUrl = pageUrl + `&search=${this.searchName}`
				}
				this.$store.dispatch('portal/getFaqz', {url: pageUrl})
					.then(_ => {
						this.loadingFaqz = false
					}).catch((err) => {
					this.loadingFaqz = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		toggleMore(status) {
			this.more = status
		},
		filterCategory(name) {
			this.categoryName = name
			let pageUrl = `?limit=${this.limit}&company_id=${this.GET_COMPANY_SETTING.id}`
			if (this.categoryName !== '') {
				pageUrl = pageUrl + `&category__id=${this.categoryName}`
			}
			this.$store.dispatch('portal/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		onSearch: debounce(function () {
			this.categoryName = ''
			let pageUrl = `?limit=${this.limit}&company_id=${this.GET_COMPANY_SETTING.id}`
			if (this.searchName !== '') {
				pageUrl = pageUrl + `&search=${this.searchName}`
			}
			if (this.categoryName !== '') {
				pageUrl = pageUrl + `&category__id=${this.categoryName}`
			}
			this.$store.dispatch('portal/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		}, 300),
		addFaq: function () {
			window.Bus.$emit('open-add-faq-modal')
		},
		addCategory: function () {
			window.Bus.$emit('open-add-faq-category-modal')
		}
	},
	mounted() {
		this.getCompanyDetails()
		// this.getFaqs()
		// this.getFaqCategory()
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
		window.Bus.$on('reload-faq', e => {
			this.getFaqs()
		})
		window.Bus.$on('reload-faq-category', e => {
			this.getFaqCategory()
		})
		window.Bus.$on('update-faq', e => {
			let faqIndex = this.GET_FAQ.data.findIndex(item => item.id === e.id)
			if (faqIndex > -1) {
				this.GET_FAQ.data[faqIndex].answer = e.data.answer
				this.GET_FAQ.data[faqIndex].question = e.data.question
				this.GET_FAQ.data[faqIndex].category = e.data.category
			}
		})
		window.Bus.$on('update-faq-category', e => {
			let faqIndex = this.GET_FAQ_CATEGORY.data.findIndex(item => item.id === e.id)
			if (faqIndex > -1) {
				this.GET_FAQ_CATEGORY.data[faqIndex].title = e.data.title
			}
		})
		window.Bus.$on('remove-faq', e => {
			let faqIndex = this.GET_FAQ.data.findIndex(item => item.id === e.id)
			if (faqIndex > -1) {
				let data = JSON.parse(JSON.stringify(this.GET_FAQ.data))
				data.splice(faqIndex, 1)
				this.GET_FAQ.data = data
			}
		})
		window.Bus.$on('remove-faq-category', e => {
			let faqIndex = this.GET_FAQ_CATEGORY.data.findIndex(item => item.id === e.id)
			if (faqIndex > -1) {
				this.GET_FAQ_CATEGORY.data.splice(faqIndex, 1)
			}
		})

	}
}
</script>

<style scoped>

</style>
